import { useRecoilValue } from 'recoil'
import {
  productsAtom,
  themesAtom,
  roleAtom,
  caseAtom,
  orderAtom,
  selectedProductsAtom,
  layoutAtom,
  funeralHomeAtom,
  selectedThemeAtom,
  stylizedVersesAtom,
  itemsLockedAtom,
  caseSelector,
  editorOrderPageSetAtom,
  editorPagesAtom,
} from 'atoms'

export type RecoilLogData = {
  products: unknown
  themes: unknown
  role: unknown
  case: unknown
  order: unknown
  selectedProducts: unknown
  layout: unknown
  funeralHome: unknown
  selectedTheme: unknown
  stylizedVerses: unknown
  itemsLocked: unknown
  editorOrderPageSet: unknown
  editorPages: unknown
  caseSelector: unknown
}

export const useRecoilLogData = (): RecoilLogData => {
  return {
    products: useRecoilValue(productsAtom),
    themes: useRecoilValue(themesAtom),
    role: useRecoilValue(roleAtom),
    case: useRecoilValue(caseAtom),
    order: useRecoilValue(orderAtom),
    selectedProducts: useRecoilValue(selectedProductsAtom),
    layout: useRecoilValue(layoutAtom),
    funeralHome: useRecoilValue(funeralHomeAtom),
    selectedTheme: useRecoilValue(selectedThemeAtom),
    stylizedVerses: useRecoilValue(stylizedVersesAtom),
    itemsLocked: useRecoilValue(itemsLockedAtom),
    editorOrderPageSet: useRecoilValue(editorOrderPageSetAtom),
    editorPages: useRecoilValue(editorPagesAtom),
    caseSelector: useRecoilValue(caseSelector),
  }
}
