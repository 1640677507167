import { load } from 'cheerio'
import type { CheerioAPI } from 'cheerio'
import type { AcceptedElems } from 'cheerio'
import preserveStylingUtilities from 'utils/tinyMCE/updateDynamicText/preserveStylingUtilities'

const preserveStyling = ({
  initialElement,
  templateElement,
}: {
  initialElement: AcceptedElems<any>
  templateElement: CheerioAPI
}) => {
  const { extractCurrentStyle, parseCSS, stringifyCSS } =
    preserveStylingUtilities()

  const currentStyle = extractCurrentStyle(initialElement)
  const $ = load('')
  const $element = $(initialElement)
  const existingStyle = $element.attr('style')
  const savedStyle = $element.attr('data-style') ?? null
  let styling = null

  if (existingStyle) {
    const savedStyleObj = parseCSS(savedStyle)
    const existingStyleObj = parseCSS(existingStyle)
    const currentStyleObj = parseCSS(currentStyle)

    /* This will merge the css inline styles taking as priority the currentStyle styles  */
    const mergedStyleObj = {
      ...savedStyleObj,
      ...existingStyleObj,
      ...currentStyleObj,
    }

    styling = stringifyCSS(mergedStyleObj)
  } else {
    styling = currentStyle || existingStyle || savedStyle
  }

  if (styling) {
    const targetDiv = templateElement('div')
    if (targetDiv.length > 0) {
      targetDiv.attr('style', styling).attr('data-style', styling)
    } else {
      console.warn('Target div not found in template element')
    }
  }
}

export default preserveStyling
