import { createRoot } from 'react-dom/client'
import Auth0 from 'providers/Auth0'
import Initiation from 'providers/Initiation'
import Routes from 'providers/Routes'
import Recoil from 'providers/Recoil'
import 'assets/css/fonts.css'
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import { datadogLogs } from '@datadog/browser-logs'
import Theme from 'providers/Theme'
import reportWebVitals from 'utils/global/reportWebVitals'
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID as string })

Sentry.init({
  dsn: 'https://94de4c3df2dd9a22c8781e9aca3f853e@o4507587149234176.ingest.us.sentry.io/4507587150675968',
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://dignity-direct-frontend-staging.vercel.app/',
    'https://dignity-direct-frontend.vercel.app/',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
  site: 'datadoghq.com',
  service: `mdl-frontend-${process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'}`,
  env: process.env.NEXT_PUBLIC_VERCEL_ENV || 'development',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
})

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)

root.render(
  <PostHogProvider 
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string}
    options={options}
  >
    <Recoil>
      <Theme>
        <Initiation>
          <Auth0>
            <Routes />
          </Auth0>
        </Initiation>
      </Theme>
    </Recoil>
  </PostHogProvider>
)

reportWebVitals(() => {})
