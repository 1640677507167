import { useState, useEffect, useCallback, useMemo } from 'react'
import * as S from './PdfRender.styled'
import axios from 'api/axios.config'
import { PagePrint } from 'components'
import {
  caseSelector,
  dateFormatAtom,
  fhLogoAtom,
  funeralHomeAtom,
} from 'atoms'
import { useSetRecoilState } from 'recoil'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { useDynamicStyles } from 'hooks'
import previewGlobal from 'styles/previewGlobal'
import funeralHomeDateFormat from 'utils/dates/funeralHomeDateFormat'
import sanitizeCaseDates from 'utils/sanitizers/sanitizeCaseDates'

const PdfRender = ({ children, isPDF, proof, ...props }) => {
  const handleError = useErrorHandler('PdfRender')
  const [pages, setPages] = useState([])
  const [data, setData] = useState({})
  const [backgroundType, setBackgroundType] = useState('PLAIN')
  const [concatenatedPages, setConcatenatedPages] = useState(0)

  const setFHLogo = useSetRecoilState(fhLogoAtom)
  const setDateFormat = useSetRecoilState(dateFormatAtom)
  const setCase = useSetRecoilState(caseSelector)
  const setFuneralHomeDetails = useSetRecoilState(funeralHomeAtom)

  useDynamicStyles(previewGlobal)

  const removeFeedbackTab = useMemo(() => {
    const addStyle = (styleString) => {
      const style = document.createElement('style')
      style.textContent = styleString
      document.head.append(style)
    }
    return () => {
      addStyle(`
        #bugmuncher_button {
          opacity: 0;
          pointer-events: none;
        }
      `)
    }
  }, [])

  const sendRequest = useCallback(async () => {
    try {
      const { data } = await axios.get(`pdfPrint`)
      data.case = sanitizeCaseDates(data.case)
      const backgroundType = data.original_layout?.background_type
      if (backgroundType) setBackgroundType(backgroundType)
      setFHLogo(data.settings?.logo)
      setCase(data.case)
      setFuneralHomeDetails(data.funeral_home)

      const format = funeralHomeDateFormat(data.settings.date_format)
      setDateFormat(format)

      const pageData = data.pages
        .map((page) => page.json_template)
        .sort(
          (a, b) => a.pageProperties.pageNumber - b.pageProperties.pageNumber,
        )

      setPages(pageData)
      setData(data)

      const concatPages = pageData[0]?.pageProperties?.concatenatedPages
      if (concatPages) setConcatenatedPages(concatPages)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }, [handleError, setCase, setDateFormat, setFHLogo, setFuneralHomeDetails])

  useEffect(() => {
    sendRequest()
    removeFeedbackTab()
    //eslint-disable-next-line
  }, [])

  return (
    <S.Div className="divWrap">
      {concatenatedPages > 0 && pages?.length > 1 ? (
        <PagePrint
          key={pages[0]?.pageProperties?.page_set_id}
          page={pages[0]}
          secondPage={pages[1]}
          theme={data.theme}
          themeLayout={data.theme_layout?.[0]}
          proof={proof}
          isPDF={isPDF}
          backgroundType={backgroundType}
        />
      ) : (
        pages.map((page, index) => (
          <PagePrint
            key={index}
            page={page}
            theme={data.theme}
            themeLayout={data.theme_layout?.[0]}
            proof={proof}
            isPDF={isPDF}
            backgroundType={backgroundType}
          />
        ))
      )}
    </S.Div>
  )
}

export default PdfRender
