import type { EditorProps } from 'types/tinyMCE'
import applyStylingToNonEditable from './applyStylingToNonEditable'
import {
  nameComponents,
  nameComponentsFormatted,
  formFieldKeys,
} from '@mdl/shared-consts'

type NameComponent = keyof typeof nameComponents

type NameOrder = {
  components: NameComponent[]
  separator: string
}

const DEFAULT_NAME_ORDER: NameOrder = {
  components: [
    nameComponents.prefix,
    nameComponents.first,
    nameComponents.nickname,
    nameComponents.middle,
    nameComponents.maiden,
    nameComponents.last,
    nameComponents.suffix,
  ],
  separator: ' ',
}

const COMPONENT_LABELS: Record<NameComponent, string> = {
  prefix: nameComponentsFormatted.prefix,
  first: nameComponentsFormatted.first,
  nickname: nameComponentsFormatted.nickname,
  middle: nameComponentsFormatted.middle,
  last: nameComponentsFormatted.last,
  suffix: nameComponentsFormatted.suffix,
  maiden: nameComponentsFormatted.maiden,
}

const registerNameReorderPlugin = (editorProps: EditorProps) => {
  const editorFunctions = applyStylingToNonEditable(editorProps)
  const { editor, caseDetails } = editorProps

  // Add the hover effect styles to the editor's content
  editor.contentStyles.push(`
    span[data-case-detail="name_of_deceased"] {
      position: relative;
      transition: all 0.2s ease;
    }
    span[data-case-detail="name_of_deceased"] > span {
      display: inline;
    }
    span[data-case-detail="name_of_deceased"]:hover {
      outline: 2px solid #4a90e2;
      outline-offset: 2px;
    }
  `)

  // Register the plugin with TinyMCE
  editor.editorManager.PluginManager.add(
    'customdeceasedname',
    function (editor) {
      // Helper function to get name components from case details
      const getNameComponents = (caseDetails?: {
        prefix_dec?: string
        first_dec?: string
        nick_name_dec?: string
        middle_dec?: string
        lastdec?: string
        suffix_dec?: string
        maiden_dec?: string
      }): Record<NameComponent, string> => {
        return {
          prefix: caseDetails?.prefix_dec || '',
          first: caseDetails?.first_dec || '',
          nickname: caseDetails?.nick_name_dec || '',
          middle: caseDetails?.middle_dec || '',
          last: caseDetails?.lastdec || '',
          suffix: caseDetails?.suffix_dec || '',
          maiden: caseDetails?.maiden_dec || '',
        }
      }

      // Helper function to map component to data-case-detail attribute
      const getDataCaseDetail = (component: NameComponent): string => {
        switch (component) {
          case nameComponents.last:
            return formFieldKeys.lastName
          case nameComponents.nickname:
            return formFieldKeys.nickName
          default:
            return `${component}_dec`
        }
      }

      // Helper function to format name components according to order
      const formatNameComponents = (
        components: Record<NameComponent, string>,
        selectedComponents: NameComponent[],
        separator: string,
      ): string => {
        const formattedComponents = selectedComponents.map((component) => {
          const value = components[component]
          if (!value) return ''

          const dataCaseDetail = getDataCaseDetail(component)
          let formattedValue = value

          switch (component) {
            case nameComponents.nickname:
              formattedValue = `"${value}"`
              break
            case nameComponents.maiden:
              formattedValue = `(${value})`
              break
          }

          return `<span class="mceNonEditable" contenteditable="false" data-case-detail="${dataCaseDetail}">${formattedValue}</span>`
        })

        const innerContent = formattedComponents.filter(Boolean).join(separator)
        return `<span class="mceNonEditable" contenteditable="false" data-case-detail="name_of_deceased">${innerContent}</span>`
      }

      // Create the name reorder dialog
      const openDialog = () => {
        // First check if there's only one name_of_deceased span in the editor
        const allNameSpans = editor.dom.select(
          '[data-case-detail="name_of_deceased"]',
        )

        if (allNameSpans.length === 1) {
          // If there's only one, use it directly
          const targetNameSpan = allNameSpans[0] as HTMLElement
          handleNameSpanDialog(targetNameSpan)
          return
        }

        // If there are multiple spans, use the existing selection logic
        const selection = editor.selection.getNode()
        const nameSpan = editor.dom.getParent(
          selection,
          '[data-case-detail="name_of_deceased"]',
          editor.getBody(),
        ) as HTMLElement | null

        // If we didn't find it as a parent, check if the selection itself is the name span
        const selectedNameSpan =
          selection.getAttribute('data-case-detail') === 'name_of_deceased'
            ? (selection as HTMLElement)
            : null

        // Use either the parent span or the selected span
        const targetNameSpan = nameSpan || selectedNameSpan

        if (!targetNameSpan) {
          editor.notificationManager.open({
            text: 'To edit the deceased name, please select a name component.',
            type: 'error',
            timeout: 5000,
            closeButton: true,
            icon: 'warning',
          })
          return
        }

        handleNameSpanDialog(targetNameSpan)
      }

      // Helper function to handle the dialog for a given name span
      const handleNameSpanDialog = (selectedSpanReference: HTMLElement) => {
        // First get all values from caseDetails
        const components = getNameComponents(caseDetails || {})

        // Get the current order from existing spans if they exist
        const childSpans = selectedSpanReference.children
        let initialComponents: NameComponent[] = []
        let displayOrder: NameComponent[] = []

        if (childSpans.length > 0) {
          // Map spans to their components to preserve order
          Array.from(childSpans).forEach((span: Element) => {
            const detail = span.getAttribute('data-case-detail')
            if (!detail) return

            // Check if the span is visible (not display: none)
            const isVisible = (span as HTMLElement).style.display !== 'none'

            let component: NameComponent | undefined
            switch (detail) {
              case formFieldKeys.prefix:
                component = nameComponents.prefix
                break
              case formFieldKeys.firstName:
                component = nameComponents.first
                break
              case formFieldKeys.nickName:
                component = nameComponents.nickname
                break
              case formFieldKeys.middleName:
                component = nameComponents.middle
                break
              case formFieldKeys.lastName:
                component = nameComponents.last
                break
              case formFieldKeys.suffix:
                component = nameComponents.suffix
                break
              case formFieldKeys.maidenName:
                component = nameComponents.maiden
                break
            }

            if (component) {
              displayOrder.push(component)
              // Only add to initialComponents if the span is visible
              if (isVisible) {
                initialComponents.push(component)
              }
            }
          })

          // Add any remaining components from DEFAULT_NAME_ORDER that aren't already in displayOrder
          DEFAULT_NAME_ORDER.components.forEach((component) => {
            if (!displayOrder.includes(component)) {
              displayOrder.push(component)
            }
          })
        } else {
          displayOrder = [...DEFAULT_NAME_ORDER.components]
        }

        // Remove duplicates while preserving order
        initialComponents = Array.from(new Set(initialComponents))

        const dialogHtml = `
        <div class="name-components-container">
          <ul class="name-components-list">
            ${displayOrder
              .map((component) => {
                const hasValue = Boolean(components[component])
                return `
                    <li class="name-component-item" draggable="${hasValue}" data-component="${component}">
                      <button type="button" class="drag-handle left-handle" aria-label="Drag to reorder" ${!hasValue ? 'disabled' : ''}>⋮⋮</button>
                      <input type="checkbox" 
                             id="${component}"
                             ${initialComponents.includes(component) ? 'checked' : ''}
                             ${!hasValue ? 'disabled' : ''}>
                      <label for="${component}">
                        <div class="label-container">
                          <div class="label-value-pair">
                            <span class="component-label">${COMPONENT_LABELS[component]}</span>
                            ${
                              components[component]
                                ? `<span class="component-value">${
                                    component === nameComponents.nickname
                                      ? `"${components[component]}"`
                                      : component === nameComponents.maiden
                                        ? `(${components[component]})`
                                        : components[component]
                                  }</span>`
                                : '<span class="no-value">(No value)</span>'
                            }
                          </div>
                        </div>
                      </label>
                      <button type="button" class="drag-handle right-handle" aria-label="Drag to reorder" ${!hasValue ? 'disabled' : ''}>⋮⋮</button>
                    </li>
                  `
              })
              .join('')}
          </ul>
        </div>
      `

        const dialogStyles = `
        .name-components-container {
          padding: 16px;
        }
        .name-components-list {
          list-style: none;
          padding: 8px;
          margin: 0;
          border: 1px solid #ccc;
          max-height: 300px;
          overflow-y: auto;
          margin-bottom: 16px;
        }
        .name-component-item {
          display: flex;
          align-items: center;
          padding: 8px;
          margin: 4px 0;
          background: #f8f8f8;
          border: 1px solid #ddd;
          border-radius: 4px;
          user-select: none;
        }
        .drag-handle {
          cursor: grab;
          padding: 0 8px;
          color: #999;
          user-select: none;
          background: none;
          border: none;
          display: flex;
          align-items: center;
          font-size: 16px;
        }
        .drag-handle:hover {
          color: #666;
        }
        .drag-handle:focus {
          outline: none;
          color: #666;
        }
        .drag-handle:hover:not([disabled]) {
          color: #666;
          cursor: grab;
        }
        .drag-handle:focus:not([disabled]) {
          outline: none;
          color: #666;
        }
        .drag-handle:disabled {
          cursor: not-allowed;
          color: #ccc;
        }
        .left-handle {
          margin-right: 8px;
        }
        .right-handle {
          margin-left: 8px;
        }
        .name-component-item.dragging {
          opacity: 0.5;
          background: #e8f0fe;
        }
        .name-component-item input[type="checkbox"] {
          margin-right: 8px;
          margin-left: 8px;
          cursor: pointer;
        }
        .name-component-item input[type="checkbox"]:disabled {
          cursor: not-allowed;
        }
        .name-component-item label {
          flex: 1;
        }
        .name-component-item[draggable="true"] {
          cursor: move;
          cursor: grab;
        }
        .name-component-item.dragging {
          opacity: 0.5;
          background: #e8f0fe;
          cursor: grabbing;
        }
        .name-component-item.dragging .drag-handle {
          cursor: grabbing;
        }
        .label-container {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
        .label-value-pair {
          display: flex;
          align-items: baseline;
          gap: 8px;
        }
        .component-label {
          color: #666;
          font-size: 14px;
          min-width: 120px;
        }
        .component-value {
          color: #000;
          font-weight: 500;
        }
        .component-value:before {
          content: '-';
          margin-right: 8px;
          color: #666;
        }
        .no-value {
          color: #999;
          font-style: italic;
        }
        .separator-select {
          margin-top: 16px;
        }
        .separator-select label {
          margin-right: 8px;
        }
        .separator-select select {
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid #ccc;
        }
      `

        // Store event listeners for cleanup
        const listeners = new Map()
        const globalListeners = new Map()

        const cleanup = () => {
          // Remove all item-specific listeners
          listeners.forEach(
            (handlers: Map<string, EventListener>, element: HTMLElement) => {
              handlers.forEach((handler: EventListener, event: string) => {
                element.removeEventListener(event, handler)
              })
            },
          )

          // Remove global listeners
          globalListeners.forEach((handler: EventListener, event: string) => {
            document.removeEventListener(event, handler)
          })

          listeners.clear()
          globalListeners.clear()
        }

        editor.windowManager.open({
          title: 'Custom Deceased Name',
          size: 'medium',
          body: {
            type: 'panel',
            items: [
              {
                type: 'htmlpanel',
                html: `
                <style>${dialogStyles}</style>
                ${dialogHtml}
              `,
              },
            ],
          },
          buttons: [
            {
              type: 'cancel',
              text: 'Cancel',
            },
            {
              type: 'submit',
              text: 'Apply',
              primary: true,
            },
          ],
          initialData: {
            separator: ' ',
          },
          onSubmit: (api) => {
            cleanup()
            const dialog = document.querySelector('.tox-dialog')
            if (!dialog) return api.close()

            const items = Array.from(
              dialog.getElementsByClassName('name-component-item'),
            ) as HTMLElement[]

            // Get the current order from the dialog items
            const currentOrder = items.map(
              (item) => item.getAttribute('data-component') as NameComponent,
            )

            const selectedComponents = items
              .map((item) => ({
                component: item.getAttribute('data-component') as NameComponent,
                checked: item.querySelector('input')?.checked,
              }))
              .filter(({ checked }) => checked)
              .map(({ component }) => component)

            // Unlock non-editable blocks before editing
            editorFunctions.unlockNonEditableBlocks()

            if (selectedSpanReference) {
              // Store references to the original spans
              const nameSpans = new Map()

              // Get all direct child spans that are name components
              Array.from(selectedSpanReference.children).forEach((span) => {
                const detail = span.getAttribute('data-case-detail')
                if (detail && detail !== 'name_of_deceased') {
                  nameSpans.set(detail, span)
                }
              })

              // Clear only the selected span
              selectedSpanReference.innerHTML = ''

              // Add spans back in the current dialog order
              currentOrder.forEach((component, index) => {
                const value = components[component]
                if (!value) return

                const dataCaseDetail = getDataCaseDetail(component)
                const existingSpan = nameSpans.get(dataCaseDetail)
                const isSelected = selectedComponents.includes(component)

                if (existingSpan) {
                  // Add the original span with visibility based on selection
                  editor.dom.setStyle(
                    existingSpan,
                    'display',
                    isSelected ? '' : 'none',
                  )
                  selectedSpanReference.appendChild(existingSpan)
                } else {
                  // If no existing span, clone the first available span and update its content
                  const firstSpan = Array.from(
                    nameSpans.values(),
                  )[0] as HTMLElement
                  let formattedValue = value
                  switch (component) {
                    case nameComponents.nickname:
                      formattedValue = `"${value}"`
                      break
                    case nameComponents.maiden:
                      formattedValue = `(${value})`
                      break
                  }

                  if (firstSpan) {
                    // Deep clone the entire span structure
                    const newSpan = firstSpan.cloneNode(true) as HTMLElement
                    // Update only the data-case-detail attribute and visibility
                    newSpan.setAttribute('data-case-detail', dataCaseDetail)

                    // Update display style on the main span and all inner spans using TinyMCE's DOM methods
                    editor.dom.setStyle(
                      newSpan,
                      'display',
                      isSelected ? '' : 'none',
                    )
                    newSpan.querySelectorAll('span').forEach((span) => {
                      editor.dom.setStyle(
                        span,
                        'display',
                        isSelected ? '' : 'none',
                      )
                    })

                    // Find the innermost span that contains the text
                    const innerSpans = newSpan.querySelectorAll('span')
                    const lastInnerSpan = innerSpans[innerSpans.length - 1]
                    if (lastInnerSpan) {
                      lastInnerSpan.textContent = formattedValue
                    } else {
                      newSpan.textContent = formattedValue
                    }

                    selectedSpanReference.appendChild(newSpan)
                  } else {
                    // If no spans exist at all, create a basic one
                    const newSpan = editor.dom.create(
                      'span',
                      {
                        class: 'mceNonEditable',
                        contenteditable: 'false',
                        'data-case-detail': dataCaseDetail,
                      },
                      formattedValue,
                    )
                    editor.dom.setStyle(
                      newSpan,
                      'display',
                      isSelected ? '' : 'none',
                    )
                    selectedSpanReference.appendChild(newSpan)
                  }
                }

                // Add space after each visible component except the last selected one
                if (isSelected) {
                  // Find the next selected component in the current order
                  const nextSelectedIndex = currentOrder.findIndex(
                    (comp, idx) =>
                      idx > index && selectedComponents.includes(comp),
                  )

                  // Only add space if there is a next selected component
                  if (nextSelectedIndex !== -1) {
                    // Get style from the innermost span of the current component
                    const currentSpan =
                      selectedSpanReference.lastElementChild as HTMLElement
                    const innerSpans = currentSpan?.querySelectorAll('span')
                    const styleSpan =
                      innerSpans[innerSpans.length - 1] || currentSpan
                    const styleToInherit =
                      styleSpan?.getAttribute('style') || ''

                    // Create a styled space span that matches the sibling's style
                    const spaceSpan = editor.dom.create(
                      'span',
                      {
                        style: styleToInherit,
                      },
                      ' ',
                    )
                    selectedSpanReference.appendChild(spaceSpan)
                  }
                }
              })
            } else {
              // If no span exists at all, create a new basic structure
              const formatted = formatNameComponents(
                components,
                selectedComponents,
                ' ',
              )
              editor.insertContent(formatted)
            }

            // Lock non-editable blocks after editing
            editorFunctions.lockNonEditableBlocks()

            // Force a content update and wait for it to complete
            editor.fire('change')
            editor.fire('input')

            // Add a small delay to ensure the content is fully updated
            setTimeout(() => {
              api.close()
            }, 100)
          },
          onClose: cleanup,
        })

        // After dialog is open, add drag and drop functionality
        const dialog = document.querySelector('.tox-dialog')
        if (dialog) {
          const list = dialog.querySelector(
            '.name-components-list',
          ) as HTMLElement
          let draggedItem: HTMLElement | null = null

          const items = Array.from(
            dialog.getElementsByClassName('name-component-item'),
          ) as HTMLElement[]

          // Store mouseup handler reference for reuse and cleanup
          const handleGlobalMouseUp = () => {
            items.forEach((item) => item.setAttribute('draggable', 'false'))
          }
          document.addEventListener('mouseup', handleGlobalMouseUp)
          globalListeners.set('mouseup', handleGlobalMouseUp)

          items.forEach((item) => {
            const itemListeners = new Map()
            listeners.set(item, itemListeners)

            const hasValue = !item.classList.contains('disabled')
            if (!hasValue) return // Skip adding drag handlers for disabled items

            const checkbox = item.querySelector(
              'input[type="checkbox"]',
            ) as HTMLInputElement
            const handles = item.querySelectorAll('.drag-handle')

            // Prevent dragging by default
            item.setAttribute('draggable', 'false')

            // Add mousedown handler to both handles
            handles.forEach((handle) => {
              const handleMouseDown = () => {
                if (hasValue) {
                  item.setAttribute('draggable', 'true')
                }
              }
              handle.addEventListener('mousedown', handleMouseDown)
              itemListeners.set('mousedown', handleMouseDown)
            })

            // Add drag events to the item
            const handleDragStart = (e: DragEvent) => {
              if (!hasValue) {
                e.preventDefault()
                return
              }
              draggedItem = item
              setTimeout(() => item.classList.add('dragging'), 0)
            }
            const handleDragEnd = () => {
              if (draggedItem) {
                draggedItem.classList.remove('dragging')
                draggedItem = null
              }
            }
            const handleDragOver = (e: DragEvent) => {
              e.preventDefault()
              if (!draggedItem || draggedItem === item) return

              const rect = item.getBoundingClientRect()
              const midY = rect.top + rect.height / 2

              if (e.clientY < midY) {
                if (item.previousElementSibling !== draggedItem) {
                  list.insertBefore(draggedItem, item)
                }
              } else {
                const nextAfterItem = item.nextElementSibling
                if (nextAfterItem !== draggedItem) {
                  list.insertBefore(draggedItem, nextAfterItem)
                }
              }
            }

            item.addEventListener('dragstart', handleDragStart)
            item.addEventListener('dragend', handleDragEnd)
            item.addEventListener('dragover', handleDragOver)

            itemListeners.set('dragstart', handleDragStart)
            itemListeners.set('dragend', handleDragEnd)
            itemListeners.set('dragover', handleDragOver)

            // Prevent checkbox from interfering
            const handleCheckboxMouseDown = (e: MouseEvent) => {
              e.stopPropagation()
              item.setAttribute('draggable', 'false')
            }
            checkbox.addEventListener('mousedown', handleCheckboxMouseDown)
            itemListeners.set('checkboxMousedown', handleCheckboxMouseDown)
          })

          // Simple drop handler to prevent default behavior
          const handleDrop = (e: DragEvent) => {
            e.preventDefault()
          }
          list.addEventListener('drop', handleDrop)
          listeners.set(list, new Map([['drop', handleDrop]]))
        }
      }

      // Register the button that opens the dialog
      editor.ui.registry.addButton('customdeceasedname', {
        text: 'Name Order',
        tooltip: 'Configure name components and order',
        icon: 'ordered-list',
        onAction: openDialog,
        onSetup: (api) => {
          const updateButtonState = () => {
            const selection = editor.selection.getNode()
            // Check if the selection contains or is contained within a name_of_deceased span
            const nameSpan =
              editor.dom.getParent(
                selection,
                '[data-case-detail="name_of_deceased"]',
                editor.getBody(),
              ) ||
              selection.querySelector('[data-case-detail="name_of_deceased"]')
            api.setDisabled(!nameSpan)
          }

          editor.on('NodeChange', updateButtonState)
          updateButtonState()

          return () => {
            editor.off('NodeChange', updateButtonState)
          }
        },
      })
    },
  )
}

export default registerNameReorderPlugin
