import {
  caseFormSections,
  formFieldTypes,
  formFieldKeys,
  formFieldAllowedInputs,
  caseFormSectionsLabels,
  newObjects,
  typeKeys,
} from '@mdl/shared-consts'
import type { CaseForm } from '@mdl/shared-types'

const caseForm: CaseForm = {
  personalInfo: [
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.personalInfo,
    },
    {
      key: formFieldKeys.editableCaseId,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.editableCaseId,
    },
    {
      key: formFieldKeys.prefix,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.prefix,
    },
    {
      key: formFieldKeys.firstName,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.firstName,
    },
    {
      key: formFieldKeys.nickName,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.nickName,
    },
    {
      key: formFieldKeys.middleName,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.middleName,
    },
    {
      key: formFieldKeys.maidenName,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.maidenName,
    },
    {
      key: formFieldKeys.lastName,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.lastName,
    },
    {
      key: formFieldKeys.suffix,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.suffix,
    },
    {
      key: formFieldKeys.dateOfBirth,
      type: formFieldTypes.date,
      label: caseFormSectionsLabels.dateOfBirth,
    },
    {
      key: formFieldKeys.dateOfDeath,
      type: formFieldTypes.date,
      label: caseFormSectionsLabels.dateOfDeath,
    },
    {
      key: formFieldKeys.placeOfBirth,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.placeOfBirth,
    },
    {
      key: formFieldKeys.placeOfDeath,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.placeOfDeath,
    },
  ],
  familyInfo: [
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.parents,
    },
    {
      key: formFieldKeys.motherOfDeceased,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.motherOfDeceased,
    },
    {
      key: formFieldKeys.fatherOfDeceased,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.fatherOfDeceased,
    },
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.spouse,
    },
    {
      key: formFieldKeys.spouse,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.spouse,
    },
    {
      key: formFieldKeys.siblings,
      type: formFieldTypes.array,
      label: caseFormSectionsLabels.siblings,
      shortLabel: caseFormSectionsLabels.sibling,
    },
    {
      key: formFieldKeys.children,
      type: formFieldTypes.array,
      label: caseFormSectionsLabels.children,
      shortLabel: caseFormSectionsLabels.child,
    },
  ],
  additionalFamily: [
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.additionalFamily,
    },
    {
      key: formFieldKeys.additionalFamily,
      type: formFieldTypes.mappedArray,
      typeKeys: null,
      addCaseAndType: true,
      keyMappingRequired: true,
      addLabel: caseFormSectionsLabels.addAnotherGroup,
      addPlaceholder: caseFormSectionsLabels.precededBySurvivedByGrandchildren,
      allowedInputs: formFieldAllowedInputs.additionalFamily,
      newObject: newObjects.additionalFamily,
    },
  ],
  serviceInfo: [
    {
      key: formFieldKeys.services,
      type: formFieldTypes.nestedArray,
      typeKeys: [typeKeys.visitation, typeKeys.service],
      addCaseAndType: true,
      allowedInputs: formFieldAllowedInputs.services,
      newObject: newObjects.service,
    },
    {
      key: formFieldKeys.orderOfService,
      type: formFieldTypes.textarea,
      label: caseFormSectionsLabels.orderOfService,
    },
    {
      key: formFieldKeys.clergy,
      type: formFieldTypes.array,
      label: caseFormSectionsLabels.clergy,
      shortLabel: caseFormSectionsLabels.clergy,
    },
  ],
  musicSelections: [
    {
      key: formFieldKeys.songs,
      type: formFieldTypes.array,
      label: caseFormSectionsLabels.musicSelections,
      shortLabel: caseFormSectionsLabels.song,
    },
    {
      key: formFieldKeys.specialSongs,
      type: formFieldTypes.array,
      label: caseFormSectionsLabels.specialSongs,
      shortLabel: caseFormSectionsLabels.song,
    },
  ],
  [caseFormSections.pallBearersSocieties]: [
    {
      key: formFieldKeys.pallbearers,
      type: formFieldTypes.nestedArray,
      typeKeys: [typeKeys.pallbearer, typeKeys.honoraryPallbearer],
      addCaseAndType: true,
      allowedInputs: formFieldAllowedInputs.pallbearers,
      newObject: {
        name: null,
        sequence: null,
      },
    },
    {
      key: formFieldKeys.organizations,
      type: formFieldTypes.nestedArray,
      typeKeys: [typeKeys.organization],
      addCaseAndType: false,
      allowedInputs: formFieldAllowedInputs.organizations,
      newObject: {
        name: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip_code: null,
        country: null,
        website_url: null,
        sequence: null,
      },
    },
  ],
  intermentInfo: [
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.intermentInfo,
    },
    {
      key: formFieldKeys.intermentTime,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.intermentTime,
    },
    {
      key: formFieldKeys.intermentDate,
      type: formFieldTypes.date,
      label: caseFormSectionsLabels.intermentDate,
    },
    {
      key: formFieldKeys.intermentPlace,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.intermentPlace,
    },
    {
      key: formFieldKeys.intermentAddress,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.intermentAddress,
    },
    {
      key: formFieldKeys.intermentCity,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.intermentCity,
    },
    {
      key: formFieldKeys.intermentState,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.intermentState,
    },
    {
      key: formFieldKeys.intermentPostalCode,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.intermentPostalCode,
    },
  ],
  [caseFormSections.memorialContributions]: [
    {
      key: formFieldKeys.memorialContributions,
      type: formFieldTypes.nestedArray,
      typeKeys: [typeKeys.memorialContribution],
      addCaseAndType: false,
      allowedInputs: formFieldAllowedInputs.memorialContributions,
      newObject: {
        charity_of_choice: false,
        name: null,
        sequence: null,
      },
    },
  ],
  branchOfService: [
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.branchOfService,
    },
    {
      key: formFieldKeys.branchOfService,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.branchOfService,
    },
    {
      key: formFieldKeys.placeEnteredService,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.placeEnteredService,
    },
    {
      key: formFieldKeys.rankDischargedFromService,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.rankDischargedFromService,
    },
    {
      key: formFieldKeys.serviceNumber,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.serviceNumber,
    },
    {
      key: formFieldKeys.dateEnteredService,
      type: formFieldTypes.date,
      label: caseFormSectionsLabels.dateEnteredService,
    },
    {
      key: formFieldKeys.dateDischargedFromService,
      type: formFieldTypes.date,
      label: caseFormSectionsLabels.dateDischargedFromService,
    },
  ],
  firingParty: [
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.firingParty,
    },
    {
      key: formFieldKeys.ncoicName,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.ncoicName,
    },
    {
      key: formFieldKeys.chaplain,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.chaplain,
    },
    {
      key: formFieldKeys.oicName,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.oicName,
    },
    {
      key: formFieldKeys.buglarAndOrColorGuard,
      type: formFieldTypes.input,
      label: caseFormSectionsLabels.buglarAndOrColorGuard,
    },
    {
      key: formFieldKeys.firingPartiesForFuneralService,
      type: formFieldTypes.array,
      label: caseFormSectionsLabels.firingPartiesForFuneralService,
      shortLabel: caseFormSectionsLabels.party,
    },
  ],
  honors: [
    {
      key: formFieldKeys.sectionLabel,
      type: formFieldTypes.sectionLabel,
      label: caseFormSectionsLabels.honors,
    },
    {
      label: caseFormSectionsLabels.distinguishedServiceMedal,
      key: formFieldKeys.distinguishedServiceMedal,
      type: formFieldTypes.check,
    },
    {
      label: caseFormSectionsLabels.purpleHeart,
      key: formFieldKeys.purpleHeart,
      type: formFieldTypes.check,
    },
    {
      label: caseFormSectionsLabels.silverStar,
      key: formFieldKeys.silverStar,
      type: formFieldTypes.check,
    },
    {
      label: caseFormSectionsLabels.medalOfHonor,
      key: formFieldKeys.medalOfHonor,
      type: formFieldTypes.check,
    },
    {
      label: caseFormSectionsLabels.airforceCross,
      key: formFieldKeys.airforceCross,
      type: formFieldTypes.check,
    },
    {
      label: caseFormSectionsLabels.navyCross,
      key: formFieldKeys.navyCross,
      type: formFieldTypes.check,
    },
    {
      label: caseFormSectionsLabels.bronzeStar,
      key: formFieldKeys.bronzeStar,
      type: formFieldTypes.check,
    },
    {
      key: formFieldKeys.blank,
      type: formFieldTypes.blank,
    },
    {
      label: caseFormSectionsLabels.personProvidingHonors,
      key: formFieldKeys.personProvidingHonors,
      type: formFieldTypes.input,
    },
    {
      label: caseFormSectionsLabels.addOtherMedal,
      shortLabel: caseFormSectionsLabels.medal,
      key: formFieldKeys.otherMedals,
      type: formFieldTypes.array,
    },
  ],
  station: [
    {
      key: formFieldKeys.usStationsWhereServed,
      type: formFieldTypes.nestedArray,
      typeKeys: [typeKeys.stations],
      addCaseAndType: false,
      allowedInputs: formFieldAllowedInputs.stations,
      newObject: {
        station_name: null,
        departure_date: new Date(),
        departure_place: null,
        return_date: new Date(),
        return_place: null,
      },
    },
  ],
}

export default caseForm
