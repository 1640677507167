import { datadogLogs } from '@datadog/browser-logs'
import { AxiosError } from 'axios'

export enum LogLevel {
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
}

export enum EventCategory {
  CLICK = 'click',
  AUTH = 'auth',
  API = 'api',
  NAVIGATION = 'navigation',
  ERROR = 'error',
  PERFORMANCE = 'performance',
  SAVE = 'save',
  REMOVE = 'remove',
  EDIT = 'edit',
  CREATE = 'create',
  SEARCH = 'search',
  CANCEL = 'cancel',
  SUBMIT = 'submit',
  CLOSE = 'close',
  OPEN = 'open',
  TOGGLE = 'toggle',
  SHOW = 'show',
  HIDE = 'hide',
}

type LogData = {
  message: string
  error?: Error | AxiosError
  category: EventCategory
  [key: string]: unknown
}

const isDevelopment = process.env.NODE_ENV === 'development'

const formatLog = (level: LogLevel, data: LogData) => {
  const { error, ...rest } = data

  return {
    timestamp: new Date().toISOString(),
    level,
    ...rest,
    error: error
      ? {
          message: error.message,
          name: error.name,
          stack: error.stack,
        }
      : undefined,
  }
}

const logMessage = (level: LogLevel, data: LogData) => {
  if (isDevelopment) return
  const { message } = data
  const formattedLog = formatLog(level, data)
  const { error, ...log } = formattedLog

  switch (level) {
    case LogLevel.ERROR:
      datadogLogs.logger.error(message, log, error)
      break
    case LogLevel.WARN:
      datadogLogs.logger.warn(message, log, error)
      break
    case LogLevel.INFO:
      datadogLogs.logger.info(message, log, error)
      break
  }
}

export const logger = {
  error: (data: LogData) => logMessage(LogLevel.ERROR, data),
  warn: (data: LogData) => logMessage(LogLevel.WARN, data),
  info: (data: LogData) => logMessage(LogLevel.INFO, data),
}
