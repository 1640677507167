import { useState, useRef, useEffect } from 'react'
import {
  itemSelector,
  caseSelector,
  dateFormatAtom,
  rightMenuAtom,
  funeralHomeAtom,
} from 'atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { TinyMCE } from 'components'
import { sanitizeDynamicText, updateDynamicText } from 'utils'
import * as S from './PageText.styled'
import { useSetToast, useTextAutosize } from 'hooks'
import { textAutosizeTriggerAtom } from 'atoms'
import { useResizeObserver } from 'hooks/editor/useTextAutosize'

export default ({
  text: rawText,
  selected,
  dragDisabled: [dragDisabled, setDragDisabled] = [null, () => {}],
  internalHeight: [internalHeight, setInternalHeight] = [0, () => {}],
  internalWidth: [internalWidth, setInternalWidth] = [0, () => {}],
  setItemHeight,
  itemRef,
  id,
  assetLocked,
  resizingState,
  environment,
  ...props
}) => {
  const [text, setText] = useState('')
  const [editing, setEditing] = useState(false)
  const [itemState, setItemState] = useState('loading')
  const [tinyMCERefTrigger, setTinyMCERefTrigger] = useState(null)

  const TinyMCERef = useRef(null)
  const caseDetails = useRecoilValue(caseSelector)
  const funeralHomeDetails = useRecoilValue(funeralHomeAtom)
  const dateFormat = useRecoilValue(dateFormatAtom)
  const textAutosizeTrigger = useRecoilValue(textAutosizeTriggerAtom)
  const setThisItemTextValue = useSetRecoilState(
    itemSelector({ id: id, path: 'text.value' }),
  )
  const activeMenu = useRecoilValue(rightMenuAtom)
  const setToast = useSetToast()

  const applyText = (text) => {
    /* Ensure empty text boxes contain at least one space character wrapped in tinyMCE allowed html tags */
    let updatedText = '<p>&nbsp;</p>'

    const dynamicTextProps = {
      initialHtml: text,
      caseDetails,
      funeralHomeDetails,
      dateFormat,
    }
    const hasText = text.trim() !== ''

    if (hasText) {
      updatedText = updateDynamicText(dynamicTextProps)
      updatedText = sanitizeDynamicText({ html: updatedText, environment })
    }

    setText(updatedText)
  }

  useEffect(() => {
    const isItemIdle = itemState === 'idle'

    if (isItemIdle && text) {
      setContent()
      updateHeight()
    }
    // eslint-disable-next-line
  }, [text])

  useEffect(() => {
    const initialHtml = rawText.value
    applyText(initialHtml)
    // eslint-disable-next-line
  }, [caseDetails, funeralHomeDetails, dateFormat])

  useEffect(() => {
    setDragDisabled(editing)
    //eslint-disable-next-line
  }, [editing])

  const updateHeight = () => {
    const updatedHeight = TinyMCERef?.current?.bodyElement?.offsetHeight
    setItemHeight?.(updatedHeight)
    setInternalHeight?.(updatedHeight)
  }

  useEffect(() => {
    !selected && setEditing(false)
  }, [selected])

  const { x, y, width, height } = props
  const statusProps = {
    x,
    y,
    width,
    height,
    itemState,
    environment,
    hasContent: !!text,
  }
  const autosizeProps = () => ({
    itemState,
    setItemState,
    resizingState,
    internalWidth: internalWidth || width,
    internalHeight: internalHeight || height,
    TinyMCERef,
    tinyMCERefTrigger,
    applyText,
  })

  useEffect(() => {
    if (!tinyMCERefTrigger) return
    useTextAutosize(autosizeProps())

    // eslint-disable-next-line
  }, [caseDetails, textAutosizeTrigger, tinyMCERefTrigger])

  useEffect(() => {
    useResizeObserver(autosizeProps())

    // eslint-disable-next-line
  }, [resizingState, TinyMCERef?.current])

  const setContent = () => {
    if (TinyMCERef.current) {
      const content = TinyMCERef.current.getContent()
      setThisItemTextValue(content)
    }
  }

  const handleBlur = () => {
    if (TinyMCERef.current) {
      setContent()
      updateHeight()
    }
  }

  const selectContent = () => {
    if (editing) return

    const editorBody = TinyMCERef?.current?.getBody()
    if (!editorBody) return

    const targetElement = editorBody.querySelector(
      '[data-case-detail="obituary"]',
    )
    if (targetElement) {
      TinyMCERef?.current?.editorCommands?.commands?.exec?.selectall(
        targetElement,
      )
    } else {
      TinyMCERef?.current?.editorCommands?.commands?.exec?.selectall()
    }
  }

  const handleDoubleClick = () => {
    if (assetLocked) return
    selectContent()
    setEditing(true)
  }

  const [contentSizeAtStartOfResize, setContentSizeAtStartOfResize] = useState({
    width: 0,
    height: 0,
    hasResized: false,
  })

  const captureContentAtStartOfResize = () => {
    const startingToResize = resizingState === 'start'
    if (!startingToResize) return
    if (!TinyMCERef?.current) return

    const contentSize = {
      width: TinyMCERef.current.bodyElement.offsetWidth,
      height: TinyMCERef.current.bodyElement.offsetHeight,
      hasResized: true,
    }

    setContentSizeAtStartOfResize(contentSize)
  }

  useEffect(() => {
    if (!tinyMCERefTrigger) return
    captureContentAtStartOfResize()

    // eslint-disable-next-line
  }, [resizingState, tinyMCERefTrigger])

  const setTinyMCERef = (ref) => {
    if (!ref) return
    TinyMCERef.current = ref
  }

  const tinyMCEProps = {
    setToast: setToast,
    setRef: setTinyMCERef,
    setTinyMCERefTrigger,
    value: text,
    // TODO: the case doesn't render the details drop down when I pass the converted case?
    caseDetails,
    updateHeight,
    onKeyUp: updateHeight,
    // TODO: Figure out how to bring this back, I disabled it since it causes the text to
    // make a new shape before the autosizer has had a chance to run
    // onExecCommand={updateHeight}
  }

  return (
    <>
      <S.LoadingState {...statusProps} />
      {/* <S.OverflowMessage {...statusProps}>
        Overflow warning: adjust or shorten text!
      </S.OverflowMessage> */}
      <S.Text
        ref={itemRef}
        selected={selected}
        editing={editing}
        onDoubleClick={() => handleDoubleClick()}
        onBlur={handleBlur}
        {...props}
      >
        <S.TextContainer
          editing={editing}
          cantHighlight={activeMenu !== 'Elements'}
          itemState={itemState}
          resizingState={resizingState}
          contentSizeAtStartOfResize={contentSizeAtStartOfResize}
        >
          {text && <TinyMCE {...tinyMCEProps} />}
        </S.TextContainer>
      </S.Text>
    </>
  )
}
