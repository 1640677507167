import { Grid, LoadingInsertImages, SearchBar } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import * as S from '../SlideoutMenu/SlideoutMenu.styled'
import * as N from '../Items/InsertItems.styled'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  caseAtom,
  itemSelector,
  selectedItemAtom,
  designAssetsAtom,
  stylizedVersesAtom,
  unsavedChangesAtom,
} from 'atoms'
import truncate from 'utils/global/truncate'

export default ({ show, setShow, ...props }) => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const [stableSelectedItem, setStableSelectedItem] = useState(selectedItem)
  const imageItem = useRecoilValue(itemSelector({ id: selectedItem }))
  const caseImages = useRecoilValue(caseAtom)?.personal_images
  const designAssets = useRecoilValue(designAssetsAtom)
  const stylizedVerses = useRecoilValue(stylizedVersesAtom)?.stylizedVerses
  const setImageUrl = useSetRecoilState(
    itemSelector({ id: stableSelectedItem, path: 'image.url' }),
  )
  const setImageEditedUrl = useSetRecoilState(
    itemSelector({ id: stableSelectedItem, path: 'image.editedUrl' }),
  )
  const setUnsavedChanges = useSetRecoilState(unsavedChangesAtom)

  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [items, setItems] = useState([])
  const [displayContent, setDisplayContent] = useState({
    text: '',
    search: false,
    type: '',
  })

  const hasEditedUrl = !!imageItem?.image?.editedUrl

  const sanitize = (data, type) => {
    return data.map((image) => ({
      name: image?.reference || '',
      image:
        type === 'caseImage'
          ? image
          : type === 'designAsset'
          ? image.url
          : image.image_url,
    }))
  }

  useEffect(() => {
    if (!displayContent.search) return

    const filtered = search.trim()
      ? stylizedVerses.filter((verse) =>
          new RegExp(`${search}`, 'i').test(verse.reference),
        )
      : stylizedVerses

    const sanitizedItems = sanitize(filtered, 'stylizedVerse')
    setItems(sanitizedItems)
    // eslint-disable-next-line
  }, [search, stylizedVerses, imageItem])

  const getItems = async () => {
    if (!imageItem?.properties?.subtype) return

    setLoading(true)

    const subtypeConfig = {
      designAsset: {
        data: designAssets,
        text: 'Choose a Design Asset',
        search: false,
      },
      stylizedVerse: {
        data: stylizedVerses,
        text: 'Choose a Verse',
        search: true,
      },
      caseImage: {
        data: caseImages,
        text: 'Choose an Image',
        search: false,
      },
    }

    const { data, text, search } = subtypeConfig[
      imageItem.properties.subtype
    ] || {
      data: [],
      text: '',
      search: false,
    }

    setDisplayContent({ text, search, type: imageItem?.properties?.subtype })
    setItems(sanitize(data, imageItem.properties.subtype))

    setLoading(false)
  }

  useEffect(() => {
    if (imageItem?.properties?.subtype) {
      setSearch('')
      getItems()
    }
    // eslint-disable-next-line
  }, [imageItem])

  useEffect(() => {
    if (selectedItem) {
      setStableSelectedItem(selectedItem)
    }
  }, [selectedItem])

  const swapImage = (imageUrl) => {
    if (!imageUrl) return

    if (hasEditedUrl) setImageEditedUrl(imageUrl)

    setImageUrl(imageUrl)
    setUnsavedChanges(true)
    setShow(false)
  }

  const isStylizedVerse = displayContent.type === 'stylizedVerse'

  return (
    <S.ModalSlideout
      bottom="17px"
      right="250px"
      left="250px"
      position="absolute"
      height={show ? '300px' : 0}
      transition="transform 0.25s ease-in-out, opacity 0.25s"
      show={show}
      background="white"
      opacity={show ? 1 : 0}
      translateY={show ? '0px' : '20px'}
      borderTop="1px solid"
      borderColor="gray4"
      {...props}
    >
      <Flex column width="100%" height="300px">
        <Flex justify="space-between" align="center" padding="12px 12px">
          <Text weight="600" size="16px">
            {displayContent.text}
          </Text>
          {displayContent.search && (
            <SearchBar
              placeholder="Search"
              value={[search, setSearch]}
              margin="0 12px 0 24px"
              width="400px"
            />
          )}
          <Icon
            clickable
            onClick={() => setShow(false)}
            icon="exitModal"
            fill="gray1"
            hoverfill="gray2"
          />
        </Flex>
        <S.Content column flex="1" overflowY="hidden" overflowX="auto">
          {loading ? (
            <LoadingInsertImages />
          ) : (
            <Flex full center>
              <Grid columns={items.length} minWidth="154px" {...props}>
                {items?.map((item, index) => (
                  <Flex column center key={index}>
                    {isStylizedVerse && (
                      <Text style={{ textAlign: 'center' }}>
                        {item?.name?.length > 20
                          ? truncate(item?.name, 15)
                          : item?.name}
                      </Text>
                    )}
                    <N.ImageItem
                      key={index}
                      align="center"
                      justify="space-between"
                      column
                      clickable
                      width="154px"
                      height="176px"
                      margin="8px"
                      radius="4px"
                      padding="16px"
                      border="1px solid"
                      borderColor="gray4"
                      background={'white'}
                      transition=".25s"
                      position="relative"
                      onClick={() => swapImage(item?.image)}
                      {...props}
                    >
                      <N.Image
                        full
                        flex="1"
                        src={item?.image}
                        backgroundColor={isStylizedVerse && '#EDEFF0'}
                      />
                    </N.ImageItem>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          )}
        </S.Content>
      </Flex>
    </S.ModalSlideout>
  )
}
