export const applySelectedFontSizeToElement = (
  editor: Editor,
  querySelector: string,
) => {
  if (!editor || !querySelector) return

  const fontSizeValue = editor.queryCommandValue('FontSize') as string
  const editorBody = editor.getBody()

  if (!editorBody) return

  const targetElement = editorBody.querySelector(
    querySelector,
  ) as HTMLElement | null

  if (!targetElement) return

  editor.formatter.apply('removeFontSize')
  targetElement.style.fontSize = fontSizeValue

  updateStyle(targetElement, 'data-style', fontSizeValue)
  updateStyle(targetElement, 'data-mce-style', fontSizeValue)
}

const updateStyle = (
  targetElement: HTMLElement,
  attribute: string,
  fontSizeValue: string,
) => {
  if (!attribute) return
  const currentElementStyle = targetElement.getAttribute(attribute) || ''
  const updatedElementStyle = updateFontSizeStyleAttribute(
    currentElementStyle,
    fontSizeValue,
  )
  targetElement.setAttribute(attribute, updatedElementStyle)
}

const updateFontSizeStyleAttribute = (
  styleString: string,
  fontSize: string,
) => {
  const styles = styleString
    .split(';')
    .map((s) => s.trim())
    .filter((s) => s.length > 0 && !s.startsWith('font-size'))

  styles.push(`font-size: ${fontSize}`)
  return styles.join('; ')
}
