import styled, { css } from 'styled-components'
import editorItem from 'styles/editorItem'
import itemFeather from 'styles/itemFeather'
import getBoxShadow from 'styles/getImageBoxShadow'

export const Image = styled.div(({ imgSrc, edits }) => {

  return css`
    ${editorItem}
    background: url("${imgSrc}");
    background-size: cover;
    background-position: center;
    overflow: ${edits?.feather ? 'hidden' : 'visible'};
    box-shadow: ${getBoxShadow(edits)};
    border: ${edits?.borderThickness}px ${edits?.borderThickness && 'solid'}
      ${edits?.borderColor};
    border-radius: ${edits?.circle ? '50%' : `${edits?.borderRadius || 0}px`};
    opacity: ${edits?.opacity};
    ${itemFeather(edits)}
  `
})
